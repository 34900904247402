html, body, #root{
	width:100%;
	height:100%;
	background:lightgray;
}

.App{
	
	text-align:center;
	
}

#display{
	
	text-align:center;
	height:50px;
	width:200px;
	background:lightblue;
}

#drum-machine{
	
	width:250px;
	margin:50px auto;
	background:gray;
	padding:50px 50px 30px 50px;
}

